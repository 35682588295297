import React, { lazy, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Shell, NotFoundPage } from '@hayu/hayu-ui';

import { mainNavigation } from './routes';
import { properties } from './modules/property/config';

import logo from './assets/logo.svg';

const PropertiesPage = lazy(() => import('./modules/property/pages/PropertiesPage'));
const PropertyPage = lazy(() => import('./modules/property/pages/PropertyPage'));

const navigation = [
  ...mainNavigation,
  ...properties.map((property) => ({ path: `/property/${property}`, name: property })),
];

const Main = () => {
  const [user, setUser] = useState<Record<string, any>>();

  const handleSingOut = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      // eslint-disable-next-line
      console.error('error signing out: ', error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
      } catch (error) {
        // eslint-disable-next-line
        console.error('Can not get user data', error);
      }
    })();
  }, []);

  return (
    <>
      <CssBaseline />
      <Shell
        userName={user?.username}
        logoUrl={logo}
        mainNavigation={navigation}
        appTitle="Optimiser"
        headerProps={{
          title: 'Optimiser',
          onSignOut: handleSingOut,
        }}
      >
        <Switch>
          <Route exact path="/" component={PropertiesPage} />
          <Route exact path="/property" component={PropertiesPage} />
          <Route exact path="/property/:name" component={PropertyPage} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Shell>
    </>
  );
};

export default Main;
