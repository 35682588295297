import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import { hayuThme } from '@hayu/hayu-ui';

import awsExports from './aws-exports';

import Main from './Main';

Amplify.configure(awsExports);

const theme = createTheme(hayuThme);

const App = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ThemeProvider>
  </Suspense>
);

export default withAuthenticator(App);
