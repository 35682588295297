/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://arcopusd4jcw3n2dtw2zw6e3wq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-tfgq4rbhs5fqhdbtyxku7f7ywm",
    "aws_cognito_identity_pool_id": "eu-west-1:8c668183-a891-4281-8167-75b31bd7ab11",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_sP8AybJ0H",
    "aws_user_pools_web_client_id": "1l3j4u9on1ds5vr9becnqo671e",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "hayuoptimiser-20210713214157-hostingbucket-proda",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d28svpaad7iaft.cloudfront.net"
};


export default awsmobile;
